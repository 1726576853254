<template>
  <v-app>
    <home-app-bar />

    <home-view />

    <home-footer
      v-if="footer"
    />

    <!-- <home-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeView: () => import('@/layouts/home/View'),
      HomeFooter: () => import('@/layouts/home/Footer'),
      // HomeSettings: () => import('@/layouts/home/Settings'),
      // HomeSystemBar: () => import('@/layouts/home/SystemBar'),
    },

    data: () => ({
      footer: false,
    }),

    mounted () {
      setTimeout(() => {
        this.footer = true
      }, 200)
    },
  }
</script>
